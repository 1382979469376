<template>
  <div class="container">
    <form @submit.prevent="resetPassword" v-if="!emailSent">
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input class="input form-control" type="email" v-model="email" placeholder="Email address"/>
        </div>
        <div>
          <button class="button">Send email</button>
        </div>
      </div>
    </form>
    <form @submit.prevent="confirmNewPassword" v-if="emailSent && !passwordReset">
      <div>
        A confirmation code has been sent to your email.
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input class="input form-control" type="password" v-model="confirmationCode" placeholder="Confirmation code"/>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <input class="input form-control" type="password" v-model="newPassword" placeholder="New password"/>
        </div>
      </div>
      <div>
        <button class="button">Set new Password</button>
      </div>
    </form>
    <div v-if="errorMessage">
      <p class="errorMessage">
        {{ errorMessage }}
      </p>
    </div>
    <div v-if="passwordReset">
      <p>
        Your password has been changed!
      </p>
      <p>
        Login
        <router-link to="/login">here</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import {AttiniAuth} from "@/AttiniAuth";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: '',
      emailSent: false,
      passwordReset: false,
      confirmationCode: '',
      newPassword: '',
      errorMessage: ''
    }
  },
  methods: {
    async resetPassword() {
      AttiniAuth.resetPassword(this.email)
          .then(() => this.emailSent = true)
          .catch((error) => {
            if (error.code === "LimitExceededException"){
              this.errorMessage = "The attempt limit has been exceeded, please try again later"
            }else {
              this.errorMessage = "Something went wrong, please wait a bit and try again. If the problem persist please contact Attini support."
            }
          })
    },
    async confirmNewPassword() {
      AttiniAuth.confirmPassword(this.email, this.confirmationCode, this.newPassword)
          .then(() => this.passwordReset = true)
          .catch(reason => {
            if(reason.code === "CodeMismatchException"){
              this.errorMessage = "Verification code does not match";
            }else if(reason.code === "InvalidPasswordException"){
                this.errorMessage = reason.message;
            }else {
              console.log(reason);
              this.errorMessage = "Something went wrong, please wait a bit and try again. If the problem persist please contact Attini support.";
            }
          });
    }
  }
}
</script>

<style scoped>

</style>