<template>
<div class="container">
  <h4>Select a new password</h4>
  <form @submit.prevent="changePassword" class="new-password-form">
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <input class="input form-control" type="password" v-model="password" placeholder=" new password..." :class="{'is-invalid' : !isValid}" />
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <input class="input form-control" type="password" v-model="repeatedPassword" placeholder="repeat new password..." :class="{'is-invalid' : !isValid}" />
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-6" v-if="!isValid">
        Password and repeated password is not the same
      </div>
    </div>
    <div v-if="errorMessage">
      <p class="errorMessage">
        {{ errorMessage }}
      </p>
    </div>
    <button class="button">Change password</button>
  </form>
</div>
</template>

<script>

import {AttiniAuth} from "@/AttiniAuth";

export default {
  name: "ChangeTempPassword",
  props: {
    user: Object
  },
  data(){
    return {
      password : '',
      repeatedPassword: '',
      isValid: true,
      errorMessage: ''
    }
  },
  methods: {
    async changePassword(){
      if(this.password !== this.repeatedPassword){
        this.isValid = false;
      }else{
        await AttiniAuth.changeTempPassword(this.user, this.password).then(() => this.$router.push("/"))
            .catch(reason => {
              if (reason.code === "InvalidPasswordException") {
                this.errorMessage = reason.message;
              }else {
                this.errorMessage = "Something went wrong, please wait a bit and try again. If the problem persist please contact Attini support.";
              }
            });
      }
    }
  }
}
</script>

<style scoped>

</style>