<template>
  <div class="container">
    <div v-if="!changePasswordNeeded">
      <form @submit.prevent="login" id="loginForm">
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <input class="input form-control" type="email" v-model="email" placeholder="Email address"/>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <input class="input form-control" type="password" v-model="password" placeholder="Password"/>
          </div>
        </div>

        <div>
          <div class="spinner-border" role="status" v-if="isLoggingIn">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div v-if="errorMessage && !isLoggingIn">
            <p class="errorMessage">
              {{ errorMessage }}
            </p>
          </div>
          <button class="button" v-if="!isLoggingIn">Login</button>
        </div>
      </form>
      <p>Not connected to an organization?
        <router-link to="/register">Create one</router-link>
      </p>
      <p>
        Forgot password?
        <router-link to="/reset-password">Reset it here</router-link>
      </p>
    </div>
    <ChangeTempPassword v-if="changePasswordNeeded" :user="user"></ChangeTempPassword>
  </div>

</template>

<script>

import ChangeTempPassword from "@/components/ChangeTempPassword";
import {AttiniAuth} from "@/AttiniAuth";

export default {
  name: "Login",
  components: {ChangeTempPassword},
  data() {
    return {
      errorMessage: '',
      email: '',
      password: '',
      isLoggingIn: false,
      changePasswordNeeded: false,
      user: {}
    }
  },
  created() {
    AttiniAuth.reloadUserPool();
  },
  methods: {
    async login() {
      this.isLoggingIn = true;
      try {
        await AttiniAuth.login(this.email, this.password)
            .then((value) => {
              if (value.newPasswordNeeded) {
                this.changePasswordNeeded = true;
                this.user = value;
              }
              return this.$router.push("/");
            })
            .catch((reason) => {
             if (reason.code === "NotAuthorizedException" || reason.code === "UserNotFoundException" ){
               this.errorMessage = 'Wrong user name or password.';

             }else {
               this.errorMessage = 'An error occurred, please try again later och contact Attini support';
             }
              this.isLoggingIn = false;
            });
      } catch (error) {
        this.isLoggingIn = false;
        this.errorMessage = 'An error occurred, please try again later och contact Attini support'

      }
    }
  }
}
</script>

<style scoped>
#loginForm > * {
  margin: 20px 0;
}

</style>